import type { MergedUnion } from "@kablamo/kerosene";
import capitalize from "lodash/capitalize";
import type {
  PredictionAttributes,
  PredictionProductType,
} from "../../../.rest-hooks/types/predictions.yml";

type WithAssessmentProps = {
  assessment: PredictionAttributes["assessment"];
  withAssessment: true;
};

type WithoutAssessmentProps = {
  withAssessment?: false;
};

type FormatPredictionProductTypeParams = {
  productType: PredictionProductType;
} & MergedUnion<WithAssessmentProps | WithoutAssessmentProps>;

const formatPredictionProductType = ({
  assessment,
  productType,
  withAssessment,
}: FormatPredictionProductTypeParams) => {
  if (withAssessment && assessment?.assessment === "endorsed") {
    return "Endorsed";
  }

  return productType.split("-").map(capitalize).join(" ");
};

export default formatPredictionProductType;
