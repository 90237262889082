import {
  Badge,
  EndTD,
  ExpandLess,
  ExpandMore,
  IconButton,
} from "@app/design-system";
import type { UseQueryResult } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import styled from "styled-components";
import type { GetIncidentsIdQueryResult } from "../../../../.rest-hooks/incidents";
import type { Prediction } from "../../../../.rest-hooks/types";
import {
  type PredictionAttributes,
  PredictionProduct,
  type PredictionProductType,
  PredictionStatus,
} from "../../../../.rest-hooks/types/predictions.yml";
import { getAlertLevelFromIncident } from "../../../config/alertLevel";
import { formatIncidentStatus } from "../../../config/general";
import { formatPredictionAnalyst } from "../../../config/predictionAnalyst";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import formatJobId from "../../../utils/formatJobId/formatJobId";
import formatPredictionProduct from "../../../utils/formatPredictionProduct/formatPredictionProduct";
import AlertLevelIcon from "../../ui/AlertLevelIcon/AlertLevelIcon";
import PredictionProductTypeBadge from "../../ui/PredictionProductTypeBadge/PredictionProductTypeBadge";
import PredictionSourceNameLabel from "../PredictionSourceLabel";
import PredictionStatusCell from "../PredictionStatusCell/PredictionStatusCell";
import RerunPredictionButton from "../RerunPredictionButton/RerunPredictionButton";
import IncidentCell from "./IncidentCell";
import ViewPredictionButton from "./ViewPredictionButton";

const StyledNoWrap = styled.div`
  white-space: nowrap;
`;

export const StyledTruncate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export type PredictionRow = {
  assessment: PredictionAttributes["assessment"];
  incidentQuery?: UseQueryResult<GetIncidentsIdQueryResult>;
  incidentId?: string;
  id: string;
  stage: string;
  name: string;
  model: string | null | undefined;
  jobId: string;
  sourceName: string;
  endorsedAt: number | undefined;
  analyst: string;
  validTo: number;
  validFrom: number;
  prediction: Prediction;
  predictionStatus: PredictionStatus;
  product: PredictionProduct;
  productType: PredictionProductType;
};

const columnHelper = createColumnHelper<PredictionRow>();

export const columns = [
  columnHelper.display({
    header: "Alert",
    id: "alertLevel",
    size: 80,
    cell: (props) => {
      const query = props.row.original.incidentQuery;
      if (!query) {
        return <AlertLevelIcon size="xl" alertLevel="Other" />;
      }

      return (
        <IncidentCell
          query={query}
          render={(incident) => {
            return (
              <AlertLevelIcon
                size="xl"
                alertLevel={getAlertLevelFromIncident(incident)}
              />
            );
          }}
          showError
        />
      );
    },
  }),
  columnHelper.display({
    header: "Inc. status",
    id: "incidentStatus",
    size: 102,
    cell: (props) => {
      const query = props.row.original.incidentQuery;

      if (!query) {
        return <FallbackElement />;
      }

      return (
        <IncidentCell
          query={query}
          render={(incident) => {
            return (
              formatIncidentStatus(incident.attributes.status) || (
                <FallbackElement />
              )
            );
          }}
        />
      );
    },
  }),
  columnHelper.display({
    header: "Name",
    size: 214,
    cell: (props) => {
      const query = props.row.original.incidentQuery;

      if (!query) {
        return <StyledTruncate>{props.row.original.name}</StyledTruncate>;
      }

      return (
        <IncidentCell
          query={query}
          render={(incident) => {
            return (
              <StyledTruncate>
                {incident.attributes.name || <FallbackElement />}
              </StyledTruncate>
            );
          }}
        />
      );
    },
  }),
  columnHelper.accessor("analyst", {
    enableSorting: true,
    header: "Analyst",
    size: 100,
    sortDescFirst: true,
    cell: (props) => {
      const analyst = props.cell.getValue();

      return (
        <StyledTruncate>{formatPredictionAnalyst(analyst)}</StyledTruncate>
      );
    },
  }),
  columnHelper.accessor("product", {
    enableSorting: true,
    header: "Product",
    size: 146,
    sortDescFirst: true,
    cell: (props) => {
      const product = props.cell.getValue();
      const formattedProduct = formatPredictionProduct(product);

      if (product === PredictionProduct["red-map"]) {
        return <Badge variant="redMap">{formattedProduct}</Badge>;
      }

      return formattedProduct || <FallbackElement />;
    },
  }),
  columnHelper.accessor("productType", {
    enableSorting: true,
    header: "Type",
    size: 128,
    sortDescFirst: true,
    cell: (props) => {
      const productType = props.cell.getValue();
      const { assessment, predictionStatus } = props.row.original;

      return (
        <PredictionProductTypeBadge
          assessment={assessment}
          predictionStatus={predictionStatus}
          productType={productType}
          withAssessment
        />
      );
    },
  }),
  columnHelper.accessor("validFrom", {
    enableSorting: true,
    header: "Status",
    size: 168,
    cell: (props) => {
      return (
        <PredictionStatusCell prediction={props.row.original.prediction} />
      );
    },
  }),
  columnHelper.accessor("jobId", {
    enableSorting: false,
    header: "Job #",
    size: 80,
    cell: (props) => {
      const jobId = props.cell.getValue();
      if (!jobId) {
        return <FallbackElement />;
      }

      return <StyledNoWrap>{formatJobId(jobId)}</StyledNoWrap>;
    },
  }),
  columnHelper.accessor("sourceName", {
    enableSorting: false,
    header: "Request by",
    size: 92,
    cell: (props) => {
      const sourceName = props.cell.getValue();
      return (
        <StyledNoWrap>
          <PredictionSourceNameLabel sourceName={sourceName} />
        </StyledNoWrap>
      );
    },
  }),
  columnHelper.display({
    header: "",
    id: "expander",
    size: 160,
    cell: (props) => {
      let expandButton: React.ReactNode;

      const {
        prediction: { id: predictionId },
        analyst,
        incidentId,
        predictionStatus,
      } = props.row.original;
      const isExpanded = props.row.getIsExpanded();

      if (!incidentId) {
        expandButton = (
          <IconButton
            disabled
            label="Expand"
            icon={ExpandMore}
            size="sm"
            variant="secondary"
          />
        );
      } else if (isExpanded) {
        expandButton = (
          <IconButton
            onClick={() => props.row.toggleExpanded(false)}
            icon={ExpandLess}
            label="Collapse"
            size="sm"
            variant="secondary"
          />
        );
      } else {
        expandButton = (
          <IconButton
            onClick={() => props.row.toggleExpanded(true)}
            icon={ExpandMore}
            label="Expand"
            size="sm"
            variant="secondary"
          />
        );
      }

      let viewButton: React.ReactNode;

      if (!incidentId || predictionStatus !== PredictionStatus.ERROR) {
        viewButton = (
          <ViewPredictionButton
            predictionStatus={predictionStatus}
            predictionId={predictionId}
          />
        );
      } else {
        viewButton = (
          <RerunPredictionButton
            analyst={analyst}
            incidentId={incidentId}
            triggerVariant="button"
          />
        );
      }

      return (
        <EndTD>
          {viewButton}
          {expandButton}
        </EndTD>
      );
    },
  }),
];
