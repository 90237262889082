import { Badge } from "@app/design-system";
import type { MergedUnion } from "@kablamo/kerosene";
import type { PredictionAttributes } from "../../../../.rest-hooks/types/predictions.yml";
import { getPredictionProductTypeBadgeVariant } from "../../../config/productType";
import formatPredictionProductType from "../../../utils/formatPredictionProductType/formatPredictionProductType";

type WithAssessmentProps = {
  assessment: PredictionAttributes["assessment"];
  withAssessment: true;
};

type WithoutAssessmentProps = {
  withAssessment?: false;
};

type PredictionProductTypeBadgeProps = {
  predictionStatus: PredictionAttributes["predictionStatus"];
  productType: PredictionAttributes["productType"];
} & MergedUnion<WithAssessmentProps | WithoutAssessmentProps>;

const PredictionProductTypeBadge = ({
  predictionStatus,
  productType,
  ...assessmentProps
}: PredictionProductTypeBadgeProps) => {
  const variant = getPredictionProductTypeBadgeVariant({
    predictionStatus,
    productType,
    ...assessmentProps,
  });

  const label = formatPredictionProductType({
    productType,
    ...assessmentProps,
  });

  return <Badge variant={variant}>{label}</Badge>;
};

export default PredictionProductTypeBadge;
