import { Button, IconButton, Refresh } from "@app/design-system";

export type RerunPredictionTriggerVariant = "button" | "iconButton";

interface RerunPredictionTriggerButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  triggerVariant: RerunPredictionTriggerVariant;
}

const RerunPredictionTriggerButton = ({
  onClick,
  triggerVariant,
}: RerunPredictionTriggerButtonProps) => {
  switch (triggerVariant) {
    case "button": {
      return (
        <Button variant="error" size="sm" onClick={onClick}>
          Re-run
        </Button>
      );
    }
    case "iconButton": {
      return (
        <IconButton
          icon={Refresh}
          label="Rerun failed prediction"
          onClick={onClick}
          size="sm"
          variant="secondary"
        />
      );
    }
  }
};

export default RerunPredictionTriggerButton;
