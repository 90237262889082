import type { BadgeVariant } from "@app/design-system";
import type { MergedUnion } from "@kablamo/kerosene";
import {
  type PredictionAttributes,
  PredictionProductType,
  PredictionStatus,
} from "../../.rest-hooks/types/predictions.yml";

export const PREDICTION_PRODUCT_TYPE_VARIANTS: Record<
  keyof typeof PredictionProductType,
  BadgeVariant
> = {
  [PredictionProductType.automated]: "automated",
  [PredictionProductType["manual-upload"]]: "manual",
} as const;

type WithAssessmentProps = {
  assessment: PredictionAttributes["assessment"];
  withAssessment: true;
};

type WithoutAssessmentProps = {
  withAssessment?: false;
};

type GetPredictionProductTypeBadgeVariantParams = {
  predictionStatus: PredictionStatus;
  productType: string | null | undefined;
} & MergedUnion<WithAssessmentProps | WithoutAssessmentProps>;

export function getPredictionProductTypeBadgeVariant({
  assessment,
  productType,
  predictionStatus,
  withAssessment,
}: GetPredictionProductTypeBadgeVariantParams): BadgeVariant | undefined {
  if (!productType) return;

  if (withAssessment && assessment?.assessment === "endorsed")
    return "endorsed";

  if (predictionStatus === PredictionStatus.ERROR) {
    return "disabled";
  }

  if (productType in PREDICTION_PRODUCT_TYPE_VARIANTS) {
    return PREDICTION_PRODUCT_TYPE_VARIANTS[
      productType as PredictionProductType
    ];
  }
}
