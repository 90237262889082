import { showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import { useGetIncidentsId } from "../../../../.rest-hooks/incidents";
import {
  getGetIncidentsIdPredictionsQueryKey,
  getGetPredictionsQueryKey,
  usePostPredictionsStartRun,
} from "../../../../.rest-hooks/predictions";
import {
  automatedPredictionSimulators,
  isAutomatedPredictionAnalyst,
} from "../../../config/predictionAnalyst";
import { isProduction } from "../../../utils/getEnvironment";
import RerunPredictionTriggerButton, {
  type RerunPredictionTriggerVariant,
} from "./RerunPredictionTriggerButton";

interface RerunSinglePredictionButtonProps {
  analyst: string;
  incidentId: string;
  triggerVariant: RerunPredictionTriggerVariant;
}

const RerunSinglePredictionButton = ({
  analyst,
  incidentId,
  triggerVariant,
}: RerunSinglePredictionButtonProps) => {
  const queryClient = useQueryClient();

  const { data } = useGetIncidentsId(incidentId);

  const incidentName = data?.data.data.attributes.name;

  const { mutate: startPredictionRun } = usePostPredictionsStartRun({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: getGetIncidentsIdPredictionsQueryKey(incidentId),
        });
        void queryClient.invalidateQueries({
          queryKey: getGetPredictionsQueryKey(),
        });

        showToast({
          title: "Re-run prediction",
          message: incidentName,
          variant: "success",
        });
      },
      onError: () => {
        showToast({
          title: "Unable to re-run prediction",
          message: incidentName,
          variant: "error",
        });
      },
    },
  });

  const onClick = () => {
    if (!isAutomatedPredictionAnalyst(analyst)) {
      showToast({
        variant: "error",
        title: "Unable to re-run prediction",
        message: "Could not determine appropriate simulator",
      });
      return;
    }

    const simulator = automatedPredictionSimulators[analyst];

    startPredictionRun({
      data: {
        incidentId,
        debug: !isProduction(),
        simulators: [simulator],
      },
    });
  };

  return (
    <RerunPredictionTriggerButton
      onClick={onClick}
      triggerVariant={triggerVariant}
    />
  );
};

export default RerunSinglePredictionButton;
