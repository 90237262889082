export const formatPredictionSourceName = (sourceName: string): string => {
  switch (sourceName) {
    case "user":
    // Ideally will deprecate
    // eslint-disable-next-line no-fallthrough
    case "user-manual-request": {
      return "User";
    }
    case "scheduled-refresh": {
      return "Scheduled";
    }
    case "incident-update": {
      return "Inc. update";
    }
    default: {
      return "—";
    }
  }
};
