import getConfig from "next/config";

export const isProduction = () => {
  const { publicRuntimeConfig } = getConfig();
  const { ENVIRONMENT } = publicRuntimeConfig;
  return ENVIRONMENT === "prod";
};

export const isDevelopment = () => {
  const { publicRuntimeConfig } = getConfig();
  const { ENVIRONMENT } = publicRuntimeConfig;
  return ENVIRONMENT === "dev";
};
