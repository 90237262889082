import { Button } from "@app/design-system";
import { PredictionStatus } from "../../../../.rest-hooks/types/predictions.yml";
import TaskLink from "../../util/TaskLink/TaskLink";

interface ViewPredictionButtonProps {
  predictionId: string;
  predictionStatus: PredictionStatus;
}

const ViewPredictionButton = ({
  predictionId,
  predictionStatus,
}: ViewPredictionButtonProps) => {
  const isCompleted = predictionStatus === PredictionStatus.COMPLETED;

  return isCompleted ? (
    <TaskLink
      as={`/fire-modelling/predictions/${predictionId}`}
      href={{
        pathname: "/fire-modelling/predictions/[predictionId]",
        query: { predictionId },
      }}
    >
      <Button size="sm" tabIndex={-1} variant="secondary">
        View details
      </Button>
    </TaskLink>
  ) : (
    <Button size="sm" tabIndex={-1} disabled variant="secondary">
      View details
    </Button>
  );
};

export default ViewPredictionButton;
