import { StatusMessage, Text } from "@app/design-system";
import type { Prediction } from "../../../../.rest-hooks/types";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";

interface PredictionStatusCellProps {
  prediction: Prediction;
}

const PredictionStatusCell = ({ prediction }: PredictionStatusCellProps) => {
  switch (prediction.attributes.predictionStatus) {
    case "COMPLETED":
      return (
        <Text size="bodyDefault">
          {getFormattedDateAndTime(prediction.attributes.validFrom * 1000)} to{" "}
          <br />
          {getFormattedDateAndTime(prediction.attributes.validTo * 1000)}
        </Text>
      );

    case "ERROR": {
      const formattedStartedAt = getFormattedDateAndTime(
        prediction.attributes.startedAt * 1000,
      );
      const formattedFinishedAt = getFormattedDateAndTime(
        prediction.attributes.finishedAt * 1000,
      );
      return (
        <StatusMessage
          tooltip={
            <>
              Run time {formattedStartedAt} to <br />
              {formattedFinishedAt}
            </>
          }
          variant="error"
        >
          Failed
        </StatusMessage>
      );
    }

    case "INPROGRESS": {
      const formattedStartedAt = getFormattedDateAndTime(
        prediction.attributes.startedAt * 1000,
      );

      return (
        <StatusMessage
          tooltip={`Started at ${formattedStartedAt}`}
          variant="progress"
        >
          In progress
        </StatusMessage>
      );
    }

    default:
      return <FallbackElement />;
  }
};

export default PredictionStatusCell;
