import { RadioButtonGroup } from "@app/design-system";
import { Controller, useFormContext } from "react-hook-form";

export type RerunPredictionStrategy = "single" | "all";

export interface RerunPredictionFormValues {
  strategy: RerunPredictionStrategy;
}

export const getDefaultValues = (): RerunPredictionFormValues => ({
  strategy: "single",
});

const RerunPredictionForm = () => {
  const { control } = useFormContext<RerunPredictionFormValues>();

  return (
    <Controller
      control={control}
      name="strategy"
      render={({ field }) => {
        return (
          <RadioButtonGroup label="Select an option" {...field}>
            <RadioButtonGroup.Item
              label="Re-run this prediction only"
              value="single"
            />
            <RadioButtonGroup.Item
              label="Re-run all automated predictions for this incident"
              value="all"
            />
          </RadioButtonGroup>
        );
      }}
    />
  );
};

export default RerunPredictionForm;
