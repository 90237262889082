import { Skeleton, StatusIcon } from "@app/design-system";
import type { UseQueryResult } from "@tanstack/react-query";
import type { GetIncidentsIdQueryResult } from "../../../../.rest-hooks/incidents";
import type { Incident } from "../../../../.rest-hooks/types";

interface IncidentCellProps {
  query: UseQueryResult<GetIncidentsIdQueryResult>;
  render: (incident: Incident) => React.ReactNode;
  showError?: boolean;
}

const IncidentCell = ({ query, render, showError }: IncidentCellProps) => {
  if (query.isPending) {
    return <Skeleton flex />;
  }

  if (query.isError && showError) {
    return (
      <StatusIcon
        size="xl"
        status="error"
        tooltip="Unable to load the incident for this prediction"
      />
    );
  }

  if (query.isError) {
    return <>&mdash;</>;
  }

  const incident = query.data.data.data;

  return <>{render(incident)}</>;
};

export default IncidentCell;
