import { formatPredictionSourceName } from "../../../config/predictionSourceName";

interface PredictionSourceNameLabelProps {
  sourceName: string;
}

const PredictionSourceNameLabel = ({
  sourceName,
}: PredictionSourceNameLabelProps) => {
  return <>{formatPredictionSourceName(sourceName)}</>;
};

export default PredictionSourceNameLabel;
