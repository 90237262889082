import getConfig from "next/config";
import { useBoolean } from "usehooks-ts";
import RerunPredictionModalForm from "./RerunPredictionModalForm";
import RerunPredictionTriggerButton, {
  type RerunPredictionTriggerVariant,
} from "./RerunPredictionTriggerButton";
import RerunSinglePredictionButton from "./RerunSinglePredictionButton";

const { publicRuntimeConfig } = getConfig();
const isSparkEnabled = publicRuntimeConfig.SPLIT_VIEW_ASSESSMENT_ENABLED;

interface RerunPredictionButtonProps {
  analyst: string;
  incidentId: string;
  triggerVariant: RerunPredictionTriggerVariant;
}

const RerunPredictionButton = ({
  analyst,
  incidentId,
  triggerVariant,
}: RerunPredictionButtonProps) => {
  const { setTrue: open, setFalse: close, value: isOpen } = useBoolean();

  if (!isSparkEnabled) {
    return (
      <RerunSinglePredictionButton
        analyst={analyst}
        incidentId={incidentId}
        triggerVariant={triggerVariant}
      />
    );
  }

  return (
    <>
      <RerunPredictionTriggerButton
        onClick={open}
        triggerVariant={triggerVariant}
      />
      <RerunPredictionModalForm
        analyst={analyst}
        incidentId={incidentId}
        isOpen={isOpen}
        onCancel={close}
        onComplete={close}
      />
    </>
  );
};

export default RerunPredictionButton;
