import type {
  PredictionAttributes,
  SimulatorName,
} from "../../.rest-hooks/types/predictions.yml";

export const AutomatedPredictionAnalysts = {
  PHOENIX: "PHOENIX",
  SPARK: "SPARK",
} as const;

export type AutomatedPredictionAnalyst =
  keyof typeof AutomatedPredictionAnalysts;

export const AutomatedPredictionAnalystLabels: Record<
  AutomatedPredictionAnalyst,
  string
> = {
  PHOENIX: "Phoenix",
  SPARK: "Spark",
};

export const isAutomatedPredictionAnalyst = (
  analyst: string,
): analyst is AutomatedPredictionAnalyst => {
  return analyst in AutomatedPredictionAnalysts;
};

export const automatedPredictionSimulators: Record<
  AutomatedPredictionAnalyst,
  SimulatorName
> = {
  PHOENIX: "phoenix",
  SPARK: "spark",
};

export const formatPredictionAnalyst = (
  analyst: PredictionAttributes["analyst"],
) => {
  if (analyst in AutomatedPredictionAnalystLabels) {
    return AutomatedPredictionAnalystLabels[
      analyst as keyof typeof AutomatedPredictionAnalystLabels
    ];
  }
  return analyst || "—";
};
